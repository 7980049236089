@use './owc_overwrites.scss';

@import '@one/web-components/dist/owc/owc.css';
@import '@one/icons/dist/one-icons-outlined.css';
@import '@one/icons/dist/one-icons-filled.css';
@import '@one/design-tokens/css/regular/navify/light/variables.css';
@import '@one/roche-font/roche-font.css';

html,
body {
  margin: 0;
  font-family: 'Roche Sans', Roboto, 'Helvetica Neue', sans-serif !important;
  height: 100%;
  width: 100%;
}

div {
  display: flex;
}
